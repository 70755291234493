<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>
          <ion-img
            src="assets/logo-header-toolbar.svg"
            class="img-toolbar"
          ></ion-img>
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding" fullscreen="true">
      <h1>{{ $t("Hi {name},", { name: userName }) }}</h1>
      <p>{{ $t("Welcome to Care digital assistant") }}</p>
      <h2>{{ $t("What type of user are you?") }}</h2>
      <ion-item lines="none">
        <ion-button
          size="medium"
          expand="block"
          @click.prevent="submit('cuidador')"
          >{{ $t("Caregiver") }}</ion-button
        >
      </ion-item>
      <ion-item lines="none">
        <ion-button
          size="medium"
          expand="block"
          @click.prevent="submit('beneficiario')"
          >{{ $t("Dependent") }}</ion-button
        >
      </ion-item>
      <ion-item lines="none"><hr /></ion-item>
      <ion-item lines="none" class="logout ion-padding ion-text-center">
        <a @click.prevent="logout">{{
          $t("I'm not {name}. Change user.", { name: userName })
        }}</a>
      </ion-item>
    </ion-content>
  </ion-page>
</template>
<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonImg,
  IonItem,
  IonButton,
  menuController,
  alertController
} from "@ionic/vue";
import { computed, nextTick } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import axios from "@/plugins/axios";

export default {
  name: "FulfillUserType",
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonImg,
    IonItem,
    IonButton
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const logout = () => {
      store.dispatch("auth/logout").then(() => {
        menuController.close("start");
        nextTick(() => (window.location = "/login"));
      });
    };
    const submit = async value => {
      const user = store.state.auth.user;
      user.cuidados = value;
      try {
        await axios.post("/user-fulfill", { type: value });
        store.dispatch("auth/setUser", user);
        router.push({ path: "/diary", replace: true });
      } catch (e) {
        const alert = await alertController.create({
          cssClass: "error-alert",
          header: t("Error"),
          message: t("There was an error updating your user"),
          buttons: [t("Ok")]
        });
        alert.present();
      }
    };
    return {
      userName: computed(() => store.state.auth.user.nombre),
      logout,
      submit,
      user: computed(() => store.state.auth.user)
    };
  }
};
</script>
<style scoped>
h1 {
  color: var(--ion-color-primary);
}
p {
  font-size: 1.2rem;
}
ion-button {
  width: 100%;
  width: calc(100% + 10px);
  margin-top: 1rem;
}
.logout {
  display: block;
  text-align: center;
  font-size: 0.8rem;
}
.logout a {
  display: block;
  text-align: center;
  width: 100%;
  text-decoration: underline;
}
</style>
